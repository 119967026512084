<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="card mb-2">
          <div class="card-body">
            <be-form-group
              label-for="membership-user-name"
              :label="translateAttribute('user', 'name')"
              :error="getErrors(membership.user, 'name')"
              required
            >
              <be-form-input
                id="membership-user-name"
                v-model="membership.user.name"
                :disabled="disabledUserFields"
                @change="clearErrors(membership.user, 'name')"
              />
            </be-form-group>

            <be-form-group
              v-if="!platformEnabled('email_authentication')"
              label-for="membership-user-identification-number"
              :label="$t('activerecord.attributes.user.identification_number')"
              :error="getErrors(membership.user, 'identification_number')"
              :description="$t('models.user.hints.identification_number')"
              required
            >
              <be-form-input
                id="membership-user-identification-number"
                v-model="membership.user.identification_number"
                :disabled="disabledUserFields"
                :formatter="formatIdentificationNumber"
                required
                autocomplete="off"
                inputmode="numeric"
                trim
                @change="clearErrors(membership.user, 'identification_number')"
              />
            </be-form-group>

            <be-form-group
              label-for="membership-user-email"
              :label="translateAttribute('user', 'email')"
              :error="getErrors(membership.user, 'email')"
              required
            >
              <be-form-input
                id="membership-user-email"
                v-model="membership.user.email"
                :disabled="disabledUserFields"
                type="email"
                @change="clearErrors(membership.user, 'email')"
              />
            </be-form-group>

            <be-form-group
              label-for="membership-user-phone"
              :label="translateAttribute('user', 'phone')"
              :error="getErrors(membership.user, 'phone')"
            >
              <be-tel-input
                id="membership-user-phone"
                v-model="membership.user.phone"
                :disabled="disabledUserFields"
                @change="clearErrors(membership.user, 'phone')"
              />
            </be-form-group>

            <be-alert variant="info">
              {{
                disabledUserFields
                  ? $t(
                      "components.admin_panel.administrators.membership_form.user_not_editable"
                    )
                  : $t(
                      "components.admin_panel.administrators.membership_form.user_editable"
                    )
              }}
            </be-alert>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="card mb-2">
          <div class="card-body">
            <template v-if="adminCompanies.length">
              <be-form-group
                label-for="membership-admin-companies"
                :label="$t('activerecord.models.company.other')"
              >
                <multi-select
                  :items="adminCompanies"
                  :preselected-ids="membership.administered_admin_company_ids"
                  :state="validationState(membership, 'admin_companies')"
                  value-selector="company.title"
                  key-selector="id"
                  @change="
                    (membership.administered_admin_company_ids = $event) &&
                    clearErrors(membership, 'admin_companies')
                  "
                />
              </be-form-group>

              <hr />
            </template>

            <be-form-group
              label-for="membership-policy-level"
              :label="translateAttribute('membership', 'policy_level')"
              :invalid-feedback="getErrors(membership, 'policy_level')"
              required
            >
              <be-form-radio-group
                id="membership-policy-level"
                v-model="membership.policy_level"
                :state="validationState(membership, 'policy_level')"
                :options="policyLevelOptions"
                required
                @change="clearErrors(membership, 'policy_level')"
              />
            </be-form-group>

            <be-alert variant="info">
              <span
                v-dompurify-html="policyInformation(membership.policy_level)"
              />
            </be-alert>

            <be-alert v-if="changingOwnPolicyLevel" variant="danger">
              {{
                $t(
                  "components.admin_panel.administrators.membership_form.changing_own_policy_level_warning"
                )
              }}
            </be-alert>
          </div>

          <div class="card-footer d-md-none">
            <be-button variant="primary" :loading="loading" @click="submit">
              {{ buttonText }}
            </be-button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-md-flex justify-content-end">
      <be-button variant="primary" :loading="loading" @click="submit">
        {{ buttonText }}
      </be-button>
    </div>
  </div>
</template>

<script>
import formatIdentificationNumber from "@/utils/format-identification-number";

export default {
  props: {
    adminPanelMembership: {
      type: Object,
      required: true,
    },

    adminCompanies: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      membership: this.cloneDeep(this.adminPanelMembership),
      loading: false,
    };
  },

  computed: {
    disabledUserFields() {
      if (this.membership.id && this.membership.user.id) {
        return !this.membership.user.can_accept_invitation;
      } else {
        return false;
      }
    },

    buttonText() {
      if (this.membership.user.id) {
        return this.$t("buttons.titles.save");
      } else {
        return this.$t("models.admin_panel_membership.create");
      }
    },

    policyLevelOptions() {
      return [
        {
          text: this.$t("models.admin_panel_membership.policy_levels.admin"),
          value: "admin",
        },
        {
          text: this.$t("models.admin_panel_membership.policy_levels.observer"),
          value: "observer",
        },
        {
          text: this.$t("models.admin_panel_membership.policy_levels.limited"),
          value: "limited",
        },
      ];
    },

    changingOwnPolicyLevel() {
      return (
        this.membership.user.id === this.$currentUser.id &&
        this.membership.policy_level !== "admin"
      );
    },
  },

  methods: {
    formatIdentificationNumber,

    policyInformation(policy) {
      if (policy === "limited") {
        return this.$t(
          "models.admin_panel_membership.policy_level_info.limited_html"
        );
      } else if (policy === "observer") {
        return this.$t(
          "models.admin_panel_membership.policy_level_info.observer_html"
        );
      } else if (policy === "admin") {
        return this.$t(
          "models.admin_panel_membership.policy_level_info.admin_html"
        );
      }
      return "";
    },

    async request(parameters) {
      if (this.membership.user.id) {
        return axios.patch(
          `/admin_panel/${this.$currentAdminPanel.id}/administrators/${this.membership.id}`,
          parameters
        );
      } else {
        return axios.post(
          `/admin_panel/${this.$currentAdminPanel.id}/administrators`,
          parameters
        );
      }
    },

    async confirmChangingPolicyLevel() {
      if (this.changingOwnPolicyLevel) {
        const isConfirmed = await this.promptRemovalConfirm({
          confirmButtonText: this.$t("buttons.titles.continue"),

          title: this.$t(
            "components.admin_panel.administrators.membership_form.confirm_changing_own_policy_level_title"
          ),

          description: this.$t(
            "components.admin_panel.administrators.membership_form.changing_own_policy_level_warning"
          ),
        });

        return isConfirmed;
      } else {
        return true;
      }
    },

    async submit() {
      try {
        const confirm = await this.confirmChangingPolicyLevel();
        if (!confirm) {
          return;
        }

        this.loading = true;

        const { data } = await this.request({
          admin_panel_membership: this.membership,
        });

        this.membership = data;

        if (window.location.pathname.includes("/administrators/new")) {
          window.location.href = `/admin_panel/${this.$currentAdminPanel.id}/administrators/${this.membership.id}/edit`;
        } else {
          this.loading = false;
        }
      } catch (error) {
        if (error.response?.status === 422) {
          this.membership = error.response.data;
        } else if (error.response?.status === 409 && !this.membership.id) {
          window.location.href = `/admin_panel/${this.$currentAdminPanel.id}/administrators/new`;
        } else {
          this.handleError(error);
        }
        this.loading = false;
      }
    },
  },
};
</script>
