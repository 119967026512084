<template>
  <be-editor-list-item
    class="d-block"
    :expanded="open"
    :invalid="invalid || invalidAssociations"
    draggable
    @toggle-item-expanded="toggleContent"
  >
    <template #title>
      <h5 class="d-inline">§{{ localItem.number }}</h5>
      -
      <h5 class="d-inline">{{ localItem.title }}</h5>
    </template>

    <template #right>
      <div class="col-auto">
        <div class="small text-muted ml-3 ml-md-0">
          <span v-if="formattedDuration" class="small text-muted">
            {{ formattedDuration }}
            <span>•</span>
            {{ formatDateInStockholm(new Date(itemStartTime(item)), "time") }}
            <template v-if="itemEndTime(item)">
              -
              {{ formatDateInStockholm(new Date(itemEndTime(item)), "time") }}
            </template>
          </span>

          <i
            v-if="decisions.length > 0"
            v-be-tooltip="
              $t('components.meetings.material.item.tooltips.has_decisions')
            "
            class="fal fa-gavel fa-fw"
          />

          <i
            v-if="tasks.length > 0"
            v-be-tooltip="
              $t('components.meetings.material.item.tooltips.has_tasks')
            "
            class="fal fa-tasks fa-fw"
          />

          <i
            v-if="documents.length > 0"
            v-be-tooltip="
              $t('components.meetings.material.item.tooltips.has_documents')
            "
            class="fal fa-paperclip fa-fw"
          />

          <i
            v-if="localItem.comments.length > 0"
            v-be-tooltip="
              $t('components.meetings.material.item.tooltips.has_comments')
            "
            class="fal fa-comment fa-fw"
          />
        </div>
      </div>

      <div class="col col-md-auto text-right pl-0 d-none d-lg-flex">
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          icon="fa-times"
          @click.stop="onRemoveItem"
        />
      </div>
    </template>

    <div class="card-body bg-light py-3">
      <div class="d-md-flex align-items-center">
        <material-item-rapporteur-dropdown
          v-model:rapporteur-id="localItem.user_id"
          :users="rapporteurUsers"
          @update:rapporteur-id="updateAttribute('user_id')"
        />

        <material-item-duration-dropdown
          v-if="!isMinutes"
          v-model="localItem.duration_in_seconds"
          :title="$t('components.meetings.material.item.time_estimate')"
          class="mr-md-5"
        />

        <material-item-subtitle-dropdown
          v-model="localItem.subtitle"
          @update:model-value="updateAttribute('subtitle')"
        />

        <div class="ml-auto">
          <material-item-duration-dropdown
            v-if="!isMinutes"
            v-model="localItem.recess_in_seconds"
            class="ml-auto"
            :title="$t('components.meetings.material.item.recess_after_item')"
            @update:model-value="updateAttribute('recess_in_seconds')"
          />

          <be-button
            v-if="localItem.comments.length > 0"
            v-be-modal="`comments-modal-${localItem.id || localItem.uuid}`"
            variant="outline-secondary"
            size="sm"
            icon="fa-comment"
          >
            {{ $t("components.shared.comments.title") }}
          </be-button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <be-form-group
        :label="$t('activerecord.attributes.item.title')"
        :label-for="`item-title-${localItem.id}`"
        :error="getErrors(localItem, ['title'])"
      >
        <be-form-input
          :id="`item-title-${localItem.id}`"
          v-model="localItem.title"
          required
          @input="updateAttribute('title', true)"
        />
      </be-form-group>

      <be-form-group
        v-if="isAgenda || (isMinutes && localItem.description)"
        :label="$t('components.meetings.material.item.item_description')"
        :label-for="`item-description-${localItem.id}`"
        :error="getErrors(localItem, 'description')"
      >
        <template v-if="isAgenda">
          <tiptap-text-editor
            v-if="useTiptap"
            :form-id="`item-description-${localItem.id}`"
            with-hashtags
            with-table
            :content="localItem.description"
            :error="getErrors(localItem, 'description')"
            @update:content="updateDescription"
          />

          <text-editor
            v-else
            :id="`item-description-${localItem.id}`"
            enable-hashtags
            mode="meeting"
            :content="localItem.description"
            :error="getErrors(localItem, 'description')"
            @update:content="updateDescription"
          />
        </template>

        <p
          v-if="isMinutes"
          v-dompurify-html="localItem.description"
          name=""
          class="border rounded p-2 text-muted ql-editor ql-viewer ql-viewer-raw"
        />
      </be-form-group>

      <be-form-group
        v-if="localItem.notes || (isAgenda && showNotes) || isMinutes"
        :label="$t('components.meetings.material.item.minutes_notes')"
        :label-for="`item-notes-${localItem.id}`"
        :error="getErrors(localItem, 'notes')"
      >
        <template v-if="useTiptap">
          <tiptap-text-editor
            :form-id="`item-notes-${localItem.id}`"
            :content="localItem.notes"
            with-hashtags
            with-table
            :error="getErrors(localItem, 'notes')"
            @update:content="updateNotes"
          />

          <be-link
            v-if="hashtagLegend.length > 0"
            @click="showHashtagLegend = !showHashtagLegend"
          >
            {{ $t("components.meetings.material.item.show_hashtag_legend") }}
          </be-link>

          <ul v-if="showHashtagLegend">
            <li v-for="hashtag in hashtagLegend" :key="hashtag.key">
              <strong> #{{ hashtag.key }} </strong>
              <em>{{ hashtag.value }}</em>
            </li>
          </ul>
        </template>

        <text-editor
          v-else
          :id="`item-notes-${localItem.id}`"
          :content="localItem.notes"
          mode="meeting"
          enable-hashtags
          :error="getErrors(localItem, 'notes')"
          @update:content="updateNotes"
        />
      </be-form-group>

      <div v-else>
        <be-button
          variant="outline-secondary"
          size="sm"
          @click="showNotes = true"
        >
          {{ $t("components.meetings.material.item.prepare_minutes_notes") }}
        </be-button>
      </div>
    </div>

    <decisions-follow-up-editor
      v-if="currentSubtitle('follow_up_decisions')"
      :item-id="item.id"
    />

    <tasks-follow-up-editor
      v-if="currentSubtitle('follow_up_tasks')"
      :item-id="item.id"
    />

    <inquiries-follow-up
      v-if="currentSubtitle('follow_up_inquiries')"
      :item-id="item.id"
    />

    <reports-follow-up-editor
      v-if="currentSubtitle('follow_up_reports')"
      :item-id="item.id"
    />

    <meetings-follow-up-editor
      v-if="currentSubtitle('follow_up_meetings')"
      :item-id="item.id"
    />

    <div v-if="loadingFollowUp" class="card-body">
      <be-skeleton-wrapper>
        <be-skeleton class="mb-3" width="100px" />

        <be-skeleton height="50px" />
      </be-skeleton-wrapper>
    </div>

    <div class="card-body">
      <be-form-group
        :label="$t('activerecord.attributes.item.attachments')"
        :label-for="`document-uploader-${localItem.id || localItem.uuid}`"
      >
        <document-uploader
          :id="`document-uploader-${localItem.id || localItem.uuid}`"
          reactive
          :documents="documents"
          support-select-from-archive
          :manual-save="false"
          @document-added="addDocument"
          @document-removed="removeDocument"
          @uploading="(value) => (value ? setLoadingStart() : setLoadingStop())"
        />
      </be-form-group>
    </div>

    <div v-if="decisions.length > 0" class="card-body">
      <decision-form
        v-for="(decision, index) in decisions"
        :key="`decision-${decision.id}`"
        :decision="decision"
        :index="index"
        :item="localItem"
      />
    </div>

    <hr
      v-if="decisions.length > 0 && tasks.length > 0"
      class="mx-0 border-dashed"
    />

    <div v-if="tasks.length > 0" class="card-body">
      <task-form
        v-for="(task, index) in tasks"
        :key="`task-${task.id}`"
        :task="task"
        :index="index"
        :item="localItem"
      />
    </div>

    <div class="card-footer border-top-0">
      <div class="row">
        <div class="col-12 col-md">
          <be-button
            variant="light"
            size="sm"
            icon="fa-plus"
            @click="addDecision"
          >
            {{ addDecisionButtonText }}
          </be-button>

          <be-button variant="light" size="sm" icon="fa-plus" @click="addTask">
            {{ addTaskButtonText }}
          </be-button>
        </div>

        <div
          class="col-12 col-md-auto d-flex align-items-center flex-wrap gap-2 mt-2 mt-md-0"
        >
          <be-button
            variant="danger"
            class="d-lg-none"
            @click.stop="onRemoveItem"
          >
            {{ $t("components.meetings.material.item.remove_item") }}
          </be-button>

          <be-button
            variant="primary"
            :disabled="saveDisabled || null"
            @click="saveItem"
          >
            {{ $t("buttons.titles.save") }}
          </be-button>
        </div>
      </div>
    </div>

    <template #after>
      <div
        v-if="localItem.recess_in_seconds > 0"
        :key="`editor-recess-${localItem.id}`"
        class="lined-heading text-black-50 mw-100 my-4"
      >
        {{
          $t("models.item.recess_w_time", {
            time: formatItemRecess(item),
          })
        }}
      </div>

      <be-modal
        v-if="localItem.comments.length > 0"
        :id="`comments-modal-${localItem.id || localItem.uuid}`"
        :title="$t('components.shared.comments.comments_and_activities')"
        ok-only
        ok-variant="light"
        :ok-title="$t('buttons.titles.close')"
      >
        <comments-list
          :comments="localItem.comments"
          :commentable-object="localItem"
          readonly
        />
      </be-modal>
    </template>
  </be-editor-list-item>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import debounce from "lodash/debounce";
import { toSentence } from "@/utils/text-utils";

import { EventBus } from "@/event-bus";
import { formatTimeDuration, formatDateInStockholm } from "@/utils/time";

import DecisionForm from "./DecisionForm.vue";
import DecisionsFollowUpEditor from "./follow_up/DecisionsFollowUpEditor.vue";
import TasksFollowUpEditor from "./follow_up/TasksFollowUpEditor.vue";
import InquiriesFollowUp from "./follow_up/InquiriesFollowUp.vue";
import ReportsFollowUpEditor from "./follow_up/ReportsFollowUpEditor.vue";
import MeetingsFollowUpEditor from "./follow_up/MeetingsFollowUpEditor.vue";
import TaskForm from "./TaskForm.vue";
import materialType from "./materialState";
import MaterialItemSubtitleDropdown from "./MaterialItemSubtitleDropdown.vue";
import MaterialItemDurationDropdown from "./MaterialItemDurationDropdown.vue";
import MaterialItemRapporteurDropdown from "./MaterialItemRapporteurDropdown.vue";
import BeEditorListItem from "@/components/shared/editor/BeEditorListItem.vue";
import TextUtilities from "@/mixins/textUtilities";
import TiptapTextEditor from "@/components/form/TiptapTextEditor.vue";

const AUTOSAVE_INTERVAL = 1000;

export default {
  components: {
    BeEditorListItem,
    DecisionForm,
    DecisionsFollowUpEditor,
    InquiriesFollowUp,
    MaterialItemDurationDropdown,
    MaterialItemRapporteurDropdown,
    MaterialItemSubtitleDropdown,
    MeetingsFollowUpEditor,
    ReportsFollowUpEditor,
    TaskForm,
    TasksFollowUpEditor,
    TiptapTextEditor,
  },

  mixins: [materialType, TextUtilities],

  props: {
    item: {
      type: Object,
      required: true,
    },

    open: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["open", "close"],

  data() {
    return {
      localItem: this.cloneDeep(this.item),
      updateDebounce: debounce(this.update, AUTOSAVE_INTERVAL),
      showNotes: false,
      showHashtagLegend: false,
    };
  },

  computed: {
    ...mapGetters("material", [
      "isDirty",
      "itemInvalid",
      "itemInvalidAssociations",
      "loading",
      "itemStartTimes",
    ]),

    ...mapGetters({
      users: "company/users",
      attendances: "attendances/attendances",
    }),

    decisions() {
      return this.$store.getters["material/getItemDecisions"](this.localItem);
    },

    tasks() {
      return this.$store.getters["material/getItemTasks"](this.localItem);
    },

    documents() {
      return this.$store.getters["material/getItemDocuments"](this.localItem);
    },

    addDecisionButtonText() {
      return this.isAgenda
        ? this.$t("components.meetings.material.item.add_decision_suggestion")
        : this.$t("components.meetings.material.item.add_decision");
    },

    addTaskButtonText() {
      return this.isAgenda
        ? this.$t("components.meetings.material.item.add_task_suggestion")
        : this.$t("components.meetings.material.item.add_task");
    },

    invalid() {
      return this.itemInvalid(this.localItem);
    },

    invalidAssociations() {
      return this.itemInvalidAssociations(this.localItem);
    },

    saveDisabled() {
      return this.loading || this.invalid || this.invalidAssociations;
    },

    usersAscByName() {
      return [...this.users].sort((a, b) => a.name.localeCompare(b.name));
    },

    rapporteurUsers() {
      if (this.isAgenda) {
        return this.users;
      } else {
        const users = [];
        this.attendances.forEach((attendance) => {
          const user = this.getUser(attendance.user_id);
          if (user) {
            users.push(user);
          }
        });
        return users;
      }
    },

    loadingFollowUp() {
      return (
        this.localItem.subtitle?.includes("follow_up") &&
        this.item.subtitle !== this.localItem.subtitle
      );
    },

    formattedDuration() {
      return formatTimeDuration(
        this.localItem.duration_in_seconds,
        this.company.locale
      );
    },

    hashtagLegend() {
      const results = [];
      Object.entries(this.hashtagsToValues).forEach(([key, values]) => {
        if (this.localItem.notes?.includes(`data-id="${key}"`)) {
          results.push({
            key: key,
            value: toSentence(values, this.$i18n.locale),
          });
        }
      });

      return results;
    },
  },

  watch: {
    item: {
      deep: true,

      handler(updatedItem) {
        this.updateDisabled = true;
        this.localItem = this.cloneDeep(updatedItem);
      },
    },

    localItem: {
      deep: true,

      handler() {
        if (this.invalid) {
          this.$emit("open", this.localItem.id);
        }
      },
    },
  },

  methods: {
    ...mapActions("material", [
      "updateItem",
      "removeItem",
      "createDecision",
      "createTask",
      "createItemDocument",
      "removeItemDocument",
    ]),

    ...mapMutations("material", [
      "setDirty",
      "setLoadingStart",
      "setLoadingStop",
    ]),

    formatDateInStockholm,

    async saveItem() {
      const result = await this.updateItem(this.localItem);
      if (result) {
        EventBus.emit("new-notification", {
          type: "success",
          message: this.$t("models.item.update.success"),
        });
      } else {
        EventBus.emit("new-notification", {
          type: "danger",
          message: this.$t("models.item.update.failure"),
        });
      }
    },

    async onRemoveItem() {
      let title = "";
      const itemType = this.$t("activerecord.models.item.one").toLowerCase();

      if (this.localItem.title) {
        title = this.$t("nav.confirm_delete_w_item_type_w_title", {
          item_type: itemType,
          title: this.truncateText(this.localItem.title, 64),
        });
      } else {
        title = this.$i18n.t("nav.confirm_delete_w_item_type", {
          item_type: itemType,
        });
      }

      const isConfirmed = await this.promptRemovalConfirm({
        title: title,

        description: this.$t(
          "components.meetings.material.editor.remove_item_confirm_description"
        ),
      });

      if (isConfirmed) {
        await this.removeItem(this.localItem);
      }
    },

    toggleContent() {
      if (this.open) {
        this.$emit("close", this.localItem.id);
      } else {
        this.$emit("open", this.localItem.id);
      }
    },

    addDecision() {
      this.createDecision(this.localItem);
    },

    addTask() {
      this.createTask(this.localItem);
    },

    addDocument(document) {
      this.createItemDocument({ item: this.localItem, document: document });
    },

    removeDocument(document) {
      this.removeItemDocument({ item: this.localItem, document: document });
    },

    updateNotes(value) {
      this.localItem.notes = value;
      this.updateAttribute("notes", true);
    },

    updateDescription(value) {
      this.localItem.description = value;
      this.updateAttribute("description", true);
    },

    updateAttribute(attribute, debounce = false) {
      this.clearErrors(this.localItem, attribute);
      this.setDirty(`item-${this.localItem.id}`);
      if (debounce) {
        this.updateDebounce();
      } else {
        this.update();
      }
    },

    async update() {
      const result = await this.updateItem(this.localItem);

      if (!result) {
        EventBus.emit("new-notification", {
          type: "danger",
          message: this.$t("models.item.update.failure"),
        });
      }
    },

    // check both item and localItem so we know it is both changed and saved
    currentSubtitle(subtitle) {
      return (
        this.item.subtitle === subtitle && this.localItem.subtitle === subtitle
      );
    },

    formatItemRecess(item) {
      return formatTimeDuration(
        item.recess_in_seconds,
        this.$currentUser.locale
      );
    },

    itemStartTime(item) {
      return this.itemStartTimes[item.id];
    },

    itemEndTime(item) {
      const itemStartTime = new Date(this.itemStartTimes[item.id]);
      if (!item.duration_in_seconds) {
        return null;
      }
      return itemStartTime.setSeconds(
        itemStartTime.getSeconds() + item.duration_in_seconds
      );
    },
  },
};
</script>
