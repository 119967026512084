<template>
  <be-editor-list-item
    :expanded="expanded"
    :invalid="invalid || Object.keys(errors).length > 0"
    draggable
    @toggle-item-expanded="$emit('update:expanded', !expanded)"
  >
    <template #title>
      <h5 class="d-inline my-0">§{{ number }}</h5>

      <span class="mx-2">-</span>

      <h5 class="d-inline my-0">{{ title }}</h5>
    </template>

    <template #right>
      <div class="col col-md-auto text-right pl-0 d-none d-lg-flex" @click.stop>
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          icon="fa-times"
          inline
          @click.stop="onRemoveItem"
        />
      </div>
    </template>

    <div class="d-block">
      <div class="card-body bg-light py-3">
        <div class="d-md-flex align-items-center">
          <material-item-duration-dropdown
            :model-value="durationInSeconds"
            :title="$t('components.meetings.material.item.time_estimate')"
            class="mr-md-5"
            @update:model-value="
              (seconds) => $emit('update:duration-in-seconds', seconds)
            "
          />

          <material-item-subtitle-dropdown
            :model-value="subtitle"
            @update:model-value="
              (subtitleKey) => $emit('update:subtitle', subtitleKey)
            "
          />

          <material-item-duration-dropdown
            :model-value="recessInSeconds"
            class="ml-auto"
            :title="$t('components.meetings.material.item.recess_after_item')"
            @update:model-value="
              (seconds) => $emit('update:recess-in-seconds', seconds)
            "
          />
        </div>
      </div>

      <div class="card-body">
        <be-form-group
          :label="$t('activerecord.attributes.item.title')"
          :label-for="`item-title-${$.vnode.key}`"
          :error="getErrors({ errors }, ['title'])"
          required
        >
          <be-form-input
            :id="`item-title-${$.vnode.key}`"
            :model-value="title"
            required
            @input="(title) => $emit('update:title', title)"
            @change="clearErrors({ errors }, ['title'])"
          />
        </be-form-group>

        <be-form-group
          :label="$t('components.meetings.material.item.item_description')"
          :label-for="`item-description-${$.vnode.key}`"
          :error="getErrors({ errors }, ['description'])"
        >
          <tiptap-text-editor
            v-if="agendaTemplate.use_tiptap_text"
            :form-id="`item-description-${$.vnode.key}`"
            with-hashtags
            with-table
            :content="description"
            :error="getErrors({ errors }, ['description'])"
            @update:content="(content) => $emit('update:description', content)"
          />

          <text-editor
            v-else
            :id="`item-description-${$.vnode.key}`"
            mode="meeting"
            enable-hashtags
            :content="description"
            :error="getErrors({ errors }, ['description'])"
            @update:content="(content) => $emit('update:description', content)"
          />
        </be-form-group>

        <be-form-group
          :label="$t('components.meetings.material.item.minutes_notes')"
          :label-for="`item-notes-${$.vnode.key}`"
          :error="getErrors({ errors }, ['notes'])"
        >
          <tiptap-text-editor
            v-if="agendaTemplate.use_tiptap_text"
            :form-id="`item-notes-${$.vnode.key}`"
            with-hashtags
            with-table
            :content="notes"
            :error="getErrors({ errors }, ['notes'])"
            @update:content="(content) => $emit('update:notes', content)"
          />

          <text-editor
            v-else
            :id="`item-notes-${$.vnode.key}`"
            mode="meeting"
            enable-hashtags
            :content="notes"
            :error="getErrors({ errors }, ['notes'])"
            @update:content="(content) => $emit('update:notes', content)"
          />
        </be-form-group>

        <be-button
          variant="danger"
          class="d-lg-none"
          block
          @click.stop="onRemoveItem"
        >
          {{ $t("components.meetings.material.item.remove_item") }}
        </be-button>
      </div>
    </div>

    <template #after>
      <div
        v-if="recessInSeconds > 0"
        :key="`editor-recess-${$.vnode.key}`"
        class="lined-heading text-black-50 mw-100 my-4"
      >
        {{
          $t("models.item.recess_w_time", {
            time: formatRecess,
          })
        }}
      </div>
    </template>
  </be-editor-list-item>
</template>
<script>
import MaterialItemSubtitleDropdown from "@/components/meetings/material/MaterialItemSubtitleDropdown.vue";
import MaterialItemDurationDropdown from "@/components/meetings/material/MaterialItemDurationDropdown.vue";
import BeEditorListItem from "@/components/shared/editor/BeEditorListItem.vue";
import TiptapTextEditor from "@/components/form/TiptapTextEditor.vue";
import TextUtilities from "@/mixins/textUtilities";
import { formatTimeDuration } from "@/utils/time";

export default {
  components: {
    MaterialItemSubtitleDropdown,
    MaterialItemDurationDropdown,
    BeEditorListItem,
    TiptapTextEditor,
  },

  mixins: [TextUtilities],

  inject: ["agendaTemplate"],

  props: {
    number: {
      type: Number,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: "",
    },

    subtitle: {
      type: String,
      required: false,
      default: null,
    },

    expanded: {
      type: Boolean,
      required: true,
    },

    durationInSeconds: {
      type: Number,
      required: true,
    },

    recessInSeconds: {
      type: Number,
      required: true,
    },

    description: {
      type: String,
      required: false,
      default: "",
    },

    notes: {
      type: String,
      required: false,
      default: "",
    },

    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: [
    "remove-item",
    "update:expanded",
    "update:title",
    "update:subtitle",
    "update:duration-in-seconds",
    "update:recess-in-seconds",
    "update:description",
    "update:notes",
  ],

  computed: {
    invalid() {
      return Object.keys(this.errors).length > 0;
    },

    formatRecess() {
      return formatTimeDuration(this.recessInSeconds, this.$currentUser.locale);
    },
  },

  methods: {
    async onRemoveItem() {
      let title = "";
      const itemType = this.$t("activerecord.models.item.one").toLowerCase();

      if (this.title) {
        title = this.$t("nav.confirm_delete_w_item_type_w_title", {
          item_type: itemType,
          title: this.truncateText(this.title, 64),
        });
      } else {
        title = this.$i18n.t("nav.confirm_delete_w_item_type", {
          item_type: itemType,
        });
      }

      const isConfirmed = await this.promptRemovalConfirm(title);

      if (isConfirmed) {
        this.$emit("remove-item");
      }
    },
  },
};
</script>
