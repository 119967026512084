<template>
  <div
    class="card bg-gray border-1"
    :class="{
      'mt-2': index !== 0,
    }"
  >
    <div
      class="card-header bg-gray d-flex justify-content-between align-items-center"
    >
      <div>
        {{ $t("activerecord.models.decision.one") }}

        <span class="text-muted">
          ({{ [meeting.number, index + 1].filter((row) => row).join(" - ") }})
        </span>
      </div>

      <div>
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          icon="fa-times"
          @click="handleRemove"
        />
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('description')"
            :label="translateAttribute('decision', 'description')"
            :error="getErrors(localDecision, 'description')"
            required
          >
            <be-form-textarea
              :id="inputId('description')"
              v-model="localDecision.description"
              :rows="9"
              :max-rows="20"
              :autofocus="localDecision.fresh"
              :state="validationState(localDecision, ['description'])"
              @input="updateAttribute('description', true)"
            />
          </be-form-group>
        </div>

        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('user_id')"
            :label="
              $t('components.meetings.material.decisions.assign_decision')
            "
            :error="getErrors(localDecision, 'user_id')"
          >
            <be-form-select
              :id="inputId('user_id')"
              v-model="localDecision.user_id"
              :state="validationState(localDecision, ['user_id'])"
              :options="userOptions"
              required
              @change="updateAttribute('user_id')"
            />
          </be-form-group>

          <be-form-group
            :label-for="inputId('due_at')"
            :label="translateAttribute('decision', 'due_at')"
            :error="getErrors(localDecision, 'due_at')"
          >
            <datepicker-future-buttons
              v-mode="localDecision.due_at"
              :state="validationState(localDecision, ['due_at'])"
              display-inline
              @input="updateAttribute('due_at', true)"
            />
          </be-form-group>

          <be-form-group
            v-if="platformEnabledAndSubscribed('projects')"
            :label-for="inputId('project_id')"
            :label="translateAttribute('decision', 'project_id')"
            :error="getErrors(localDecision, 'project_id')"
          >
            <be-form-select
              :id="inputId('project_id')"
              v-model="localDecision.project_id"
              :state="validationState(localDecision, ['project_id'])"
              :options="projectOptions"
              required
              @change="updateAttribute('project_id')"
            />
          </be-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

import TextUtilities from "@/mixins/textUtilities";

const AUTOSAVE_INTERVAL = 1000;

export default {
  mixins: [TextUtilities],

  props: {
    decision: {
      type: Object,
      required: true,
    },

    meeting: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  emits: ["dirty", "update", "remove"],

  data() {
    return {
      localDecision: this.cloneDeep(this.decision),
      updateDebounce: debounce(this.update, AUTOSAVE_INTERVAL),
    };
  },

  computed: {
    ...mapGetters({
      users: "company/users",
      projects: "company/projects",
    }),

    projectOptions() {
      return [
        {
          value: null,
          text: this.$t("beta_shared.please_select_an_option"),
        },
        ...this.projects.map((project) => ({
          text: project.display_title,
          value: project.id,
        })),
      ];
    },

    userOptions() {
      return [
        {
          value: null,
          text: this.$t("components.meetings.material.decisions.unassigned"),
        },
        ...this.users.map((user) => {
          return { value: user.id, text: user.name };
        }),
      ];
    },

    invalid() {
      return Object.keys(this.localDecision.errors || {}).length > 0;
    },

    descriptionErrors() {
      const value = this.getErrors(this.localDecision, "description");

      if (Array.isArray(value)) {
        return value;
      } else if (value) {
        return [value];
      } else {
        return [];
      }
    },
  },

  watch: {
    decision: {
      handler(newDecision) {
        this.localDecision = this.cloneDeep(newDecision);
      },

      deep: true,
    },
  },

  methods: {
    inputId(field) {
      return `decision-${this.decision.id}-${field}`;
    },

    async handleRemove() {
      let title = "";

      const itemType = this.$t("activerecord.models.decision.one");

      if (this.decision.description) {
        title = this.$i18n.t("nav.confirm_delete_w_item_type_w_title", {
          item_type: itemType.toLowerCase(),
          title: this.truncateText(this.decision.description, 64),
        });
      } else {
        if (this.isAgenda) {
          title = this.$t(
            "models.material.minutes.confirm_remove_decision_suggestion"
          );
        } else {
          title = this.$i18n.t("nav.confirm_delete_w_item_type", {
            item_type: this.$t(
              "activerecord.models.decision.one"
            ).toLowerCase(),
          });
        }
      }

      const { isConfirmed } = await this.promptRemovalConfirm(title);

      if (isConfirmed) {
        this.$emit("remove", this.localDecision);
      }
    },

    // Called after debounce
    async update() {
      this.$emit("update", this.localDecision);
    },

    // Called when a field changes
    updateAttribute(attribute, debounce = false) {
      this.clearErrors(this.localDecision, attribute);
      if (debounce) {
        this.updateDebounce();
      } else {
        this.update();
      }
    },
  },
};
</script>
