<template>
  <be-list-group flush>
    <profile-picture-upload section-type="user_info_fortnox" />
  </be-list-group>
</template>

<script>
import ProfilePictureUpload from "@/components/onboarding/user/ProfilePictureUpload.vue";

export default {
  components: {
    ProfilePictureUpload,
  },

  emits: ["report-and-analysis-get-started-visited"],
};
</script>
