<template>
  <div>
    <header
      class="d-flex flex-wrap justify-content-between align-items-end mb-3 gap-3"
    >
      <h1 class="mb-0">
        {{ $t("activerecord.models.user_dashboard_config.other") }}

        <div v-if="inCorporateGroup" class="small text-muted">
          {{ $t("application.corporate_groups") }}
        </div>
      </h1>
    </header>

    <div class="card mb-2">
      <div class="card-body">
        {{
          $t(
            "components.financials.dashboards.dashboard_configurations.description"
          )
        }}
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <be-alert v-if="dashboards.length === 0" variant="info">
          {{
            $t(
              "components.financials.dashboards.dashboard_configurations.no_dashboards"
            )
          }}
        </be-alert>

        <div v-else class="table-responsive">
          <!-- TODO: Change to BeTable -->
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="col-shrink"></th>

                <th>{{ $t("activerecord.attributes.report.title") }}</th>

                <th class="col-shrink">
                  {{ $t("simple_form.labels.report.global") }}
                </th>

                <th class="col-shrink">
                  {{ $t("activerecord.attributes.report.creator") }}
                </th>

                <th class="col-shrink">
                  {{ $t("activerecord.attributes.report.created_at") }}
                </th>

                <th class="col-shrink text-center">
                  {{ $t("models.user_dashboard_config.visible") }}
                </th>

                <th class="col-shrink" />
              </tr>
            </thead>

            <drop-list
              :items="dashboardsWithConfig"
              tag="tbody"
              @reorder="reorderDashboards"
            >
              <template #item="{ item }">
                <drag
                  :key="`dashboard-${item.id}`"
                  tag="tr"
                  handle=".__drag-handle"
                  :disabled="item.config.hidden_at !== null"
                >
                  <td class="col-shrink pr-0 text-muted">
                    <i
                      v-if="!item.config.hidden_at"
                      class="fas fa-grip-vertical __drag-handle"
                    />
                  </td>

                  <td>{{ item.title }}</td>

                  <td class="col-shrink text-center">
                    <i
                      v-if="item.global"
                      v-be-tooltip="
                        $t('components.reports.settings.global_tooltip')
                      "
                      class="fas fa-check"
                    />
                  </td>

                  <td class="col-shrink">
                    <span v-if="item.owner_type === 'Company'">
                      {{ getUserName(item.creator_id) }}
                    </span>

                    <span v-else>
                      {{ $t("activerecord.models.admin_panel.one") }}
                    </span>
                  </td>

                  <td class="col-shrink">
                    {{ $d(new Date(item.created_at)) }}
                  </td>

                  <td class="col-shrink text-center">
                    <be-form-checkbox
                      :checked="!item.config.hidden_at"
                      size="lg"
                      @change="(checked) => toggleVisibility(item, checked)"
                    />
                  </td>

                  <td class="col-shrink">
                    <be-dropdown
                      v-if="item.policy.edit || item.policy.destroy"
                      size="sm"
                      ellipsis
                    >
                      <be-dropdown-group>
                        <be-dropdown-item
                          v-if="item.policy.edit"
                          :href="url(`${urlPrefix}/dashboards/${item.id}/edit`)"
                        >
                          {{ $t("buttons.titles.edit") }}
                        </be-dropdown-item>
                      </be-dropdown-group>

                      <be-dropdown-group>
                        <be-dropdown-item
                          v-if="item.policy.destroy"
                          variant="danger"
                          @click="removeDashboard(item)"
                        >
                          {{ $t("buttons.titles.remove") }}
                        </be-dropdown-item>
                      </be-dropdown-group>
                    </be-dropdown>
                  </td>
                </drag>
              </template>

              <!-- This slot must be defined, even if it is empty -->
              <template #feedback></template>
            </drop-list>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sortBy from "lodash/sortBy";
import { Drag, DropList } from "vue-easy-dnd";
import { mapGetters } from "vuex";

export default {
  components: {
    Drag,
    DropList,
  },

  props: {
    dashboards: {
      type: Array,
      required: true,
    },

    configs: {
      type: Array,
      required: true,
    },
  },

  data() {
    const configs = this.cloneDeep(this.configs);
    return {
      localDashboards: this.cloneDeep(this.dashboards),
      localConfigs: configs,
      visibleConfigIds: this.visibleOrderedIds(configs),
    };
  },

  computed: {
    ...mapGetters({
      inCorporateGroup: "company/inCorporateGroup",
    }),

    dashboardById() {
      const result = {};

      this.localDashboards.forEach((dashboard) => {
        result[dashboard.id] = dashboard;
      });

      return result;
    },

    configById() {
      const result = {};

      this.localConfigs.forEach((config) => {
        result[config.id] = config;
      });

      return result;
    },

    // Uses the data attribute to allow sorting
    visibleConfigs() {
      return this.visibleConfigIds
        .map((id) => this.configById[id])
        .filter((config) => config);
    },

    hiddenConfigs() {
      return this.localConfigs.filter((config) => config.hidden_at);
    },

    dashboardsWithConfig() {
      const hidden = [];
      const visible = [];

      this.visibleConfigs.forEach((config) => {
        const withDashboard = {
          config: config,
          ...this.dashboardById[config.report_id],
        };
        visible.push(withDashboard);
      });

      this.hiddenConfigs.forEach((config) => {
        const withDashboard = {
          config: config,
          ...this.dashboardById[config.report_id],
        };

        hidden.push(withDashboard);
      });

      return visible.concat(hidden);
    },

    urlPrefix() {
      return this.inCorporateGroup ? "corporate_groups" : "financials";
    },
  },

  methods: {
    toggleVisibility(dashboard, checked) {
      if (checked) {
        this.updateConfig(dashboard, { hidden: false });
      } else {
        this.updateConfig(dashboard, { hidden: true, position: null });
      }
    },

    async updateConfig(dashboard, params) {
      try {
        const { data } = await axios.patch(dashboard.config.path, {
          dashboard_config: params,
        });

        for (let index = 0; index < this.localConfigs.length; index++) {
          const config = this.localConfigs[index];
          if (config.id === data.id) {
            this.localConfigs[index] = data;
          } else {
            config.position = data.positions[config.id];
          }
        }

        this.visibleConfigIds = this.visibleOrderedIds(this.localConfigs);
      } catch (error) {
        this.handleError(error);
      }
    },

    reorderDashboards(event) {
      const dashboard = this.dashboardsWithConfig[event.from];

      event.apply(this.visibleConfigIds);
      if (dashboard) {
        this.updateConfig(dashboard, { position: event.to + 1 });
      }
    },

    visibleOrderedIds(configs) {
      return sortBy(
        configs.filter((config) => config.hidden_at === null),
        "position"
      ).map((config) => config.id);
    },

    async removeDashboard(dashboard) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: dashboard.title,
        })
      );

      if (!isConfirmed) {
        return;
      }

      try {
        await axios.delete(
          this.url(`/${this.urlPrefix}/dashboards/${dashboard.id}`)
        );

        const dashboardIndex = this.localDashboards.findIndex(
          (obj) => obj.id === dashboard.id
        );

        if (dashboardIndex > -1) {
          this.localDashboards.splice(dashboardIndex, 1);
        }

        const configIndex = this.localConfigs.findIndex(
          (config) => config.report_id === dashboard.id
        );

        if (configIndex > -1) {
          this.localConfigs.splice(configIndex, 1);
        }
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
