<template>
  <be-dropdown size="sm" ellipsis>
    <be-dropdown-group>
      <be-dropdown-item :href="url(`/contracts/${contract.id}/edit`)">
        {{ $t("buttons.titles.edit") }}
      </be-dropdown-item>
    </be-dropdown-group>

    <be-dropdown-group>
      <be-dropdown-item
        v-if="contract.policy.destroy"
        variant="danger"
        @click="removeContract"
      >
        {{ $t("buttons.titles.remove") }}
      </be-dropdown-item>
    </be-dropdown-group>
  </be-dropdown>
</template>

<script>
export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },

  emits: ["contract-remove"],

  methods: {
    removeContract() {
      this.$emit("contract-remove");
    },
  },
};
</script>
