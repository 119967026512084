<template>
  <div>
    <template v-if="hasAnyContracts">
      <header class="mb-4">
        <h1>
          {{ $t("activerecord.models.contract.other") }}
        </h1>
      </header>

      <be-tabs
        v-model="activeTab"
        :options="tabsData"
        translation-scope="components.contracts.tabs"
      >
        <template #content-right>
          <be-button
            v-if="hasContractNotifications"
            variant="outline-secondary"
            class="text-nowrap"
            :href="url('/contracts/notifications')"
          >
            {{ $t("views.companies.contracts.notifications") }}

            <be-badge
              v-if="unreadContractNotificationsCount > 0"
              variant="notification"
              pill
              class="ml-1"
            >
              {{ unreadContractNotificationsCount }}
            </be-badge>
          </be-button>

          <template v-if="canCreateContract">
            <be-button
              v-be-tooltip="{
                title: $t('activemodel.errors.messages.storage_limit_exceeded'),
                disabled: usedStoragePercentage < 100,
              }"
              variant="outline-primary"
              class="text-nowrap"
              :disabled="usedStoragePercentage >= 100"
              :href="url('/contracts/new')"
            >
              {{ $t("models.contract.new") }}
            </be-button>
          </template>

          <be-dropdown ellipsis>
            <be-dropdown-group>
              <be-dropdown-item v-be-modal.contract-export-modal>
                {{ exportModaltitle }}
              </be-dropdown-item>
            </be-dropdown-group>

            <be-dropdown-group>
              <be-dropdown-item
                v-if="canSendRequest"
                v-be-modal.contract-request
              >
                {{ $t("components.contracts.contract_tabs.contract_request") }}
              </be-dropdown-item>
            </be-dropdown-group>

            <be-dropdown-group class="d-block d-md-none">
              <be-dropdown-item href="url('contracts/notifications')">
                {{
                  $t(
                    "components.contracts.contract_tabs.show_all_notifications"
                  )
                }}
              </be-dropdown-item>
            </be-dropdown-group>

            <template v-if="canCreateContract">
              <be-dropdown-group>
                <be-dropdown-item
                  v-if="canCreateCategory"
                  :href="url('contracts/categories')"
                >
                  {{ $t("application.categories") }}
                </be-dropdown-item>

                <be-dropdown-item
                  v-if="canCreateCustomField"
                  :href="url('contracts/custom_fields')"
                >
                  {{ $t("application.custom_fields") }}
                </be-dropdown-item>
              </be-dropdown-group>

              <be-dropdown-group v-if="canUpdateCompany">
                <be-dropdown-item :href="url('/tokens')">
                  {{ $t("application.tokens") }}
                </be-dropdown-item>

                <be-dropdown-item :href="url('/settings#contracts')">
                  {{ $t("components.contracts.contract_tabs.settings") }}
                </be-dropdown-item>
              </be-dropdown-group>
            </template>
          </be-dropdown>
        </template>
      </be-tabs>

      <div v-show="activeTab === tabsData.contracts.value" class="card">
        <div class="card-body">
          <contract-list
            :categories="categories"
            :custom-fields="customFields"
          />
        </div>
      </div>

      <div v-if="activeTab === tabsData.gantt.value" class="card">
        <div class="card-body">
          <gantt-chart
            :initial-last-possible-date="initialLastPossibleDate"
            class="d-none d-md-block"
          />
        </div>
      </div>

      <div v-if="activeTab === tabsData.receivedContracts.value" class="card">
        <div class="card-body">
          <p>
            {{
              $t(
                "components.contracts.contract_tabs.received_contracts_description_1"
              )
            }}
          </p>

          <h3>
            {{
              $t("components.contracts.contract_tabs.received_contracts_header")
            }}
          </h3>

          <ul>
            <li>
              {{
                $t(
                  "components.contracts.contract_tabs.received_contracts_description_2"
                )
              }}
            </li>

            <li>
              {{
                $t(
                  "components.contracts.contract_tabs.received_contracts_description_3"
                )
              }}
            </li>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <li v-html="descriptionWithLink"></li>

            <li>
              {{
                $t(
                  "components.contracts.contract_tabs.received_contracts_description_5"
                )
              }}
            </li>
          </ul>

          <be-table
            v-if="receivedContracts.length > 0"
            :items="receivedContracts"
            :fields="fields"
            sort-by="created_at"
          >
            <template #title="{ item }">
              <a :href="url(`/contracts/${item.id}`)">
                {{ `#${item.contract_number} - ${item.title}` }}
              </a>
            </template>

            <template #created_at="{ item }">
              {{ $d(new Date(item.created_at), "dateTime") }}
            </template>

            <template #administrator="{ item }">
              <user-avatar
                v-if="item.administrator_id"
                :user="item.administrator_id"
              />
            </template>

            <template #responsible_user="{ item }">
              <user-avatar
                v-if="item.responsible_user_id"
                :user="item.responsible_user_id"
              />
            </template>

            <template #actions="{ item }">
              <contract-dropdown
                v-if="item.policy.edit"
                :contract="item"
                @contract-remove="removeReceivedContract(item)"
              />
            </template>
          </be-table>

          <be-alert v-else variant="info">
            {{ $t("components.contracts.contract_tabs.no_received_contracts") }}
          </be-alert>
        </div>
      </div>
    </template>

    <div v-else class="card">
      <div class="card-header">
        <h5 class="card-title">
          {{ $t("activerecord.models.contract.other") }}
        </h5>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-6 order-2 order-md-1">
            <p>{{ $t("components.contracts.contract_tabs.description1") }}</p>

            <p class="mb-4">
              {{ $t("components.contracts.contract_tabs.description2") }}
            </p>

            <be-button
              v-if="platformAdmin || isContractAdmin"
              :href="url(`/contracts/new`)"
            >
              {{ $t("models.contract.new") }}
            </be-button>

            <be-button
              v-if="hasContractNotifications"
              :href="url(`/contracts/notifications`)"
              variant="outline-secondary"
            >
              {{ $t("views.companies.contracts.notifications") }}
              <be-badge
                v-if="unreadContractNotificationsCount > 0"
                variant="danger"
                pill
                class="ml-1"
              >
                {{ unreadContractNotificationsCount }}
              </be-badge>
            </be-button>
          </div>

          <div class="col-12 col-md-6 order-1 order-md-2 mb-3 mb-md-0">
            <be-img :src="imageSrc('onboarding/intro/contracts.png')" />
          </div>
        </div>
      </div>
    </div>

    <storage-usage
      v-if="contracts.length > 0 || receivedContracts.length > 0"
      class="mt-2"
    />

    <export-modal
      id="contract-export-modal"
      :export-types="['contract_pdf', 'contract_active_pdf']"
      :title="exportModaltitle"
    />

    <contract-request-modal v-if="canSendRequest" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ContractDropdown from "./ContractDropdown.vue";
import ContractList from "./ContractList.vue";
import ContractRequestModal from "./ContractRequestModal.vue";
import GanttChart from "./GanttChart.vue";

const TABS = {
  contracts: "contracts",
  gantt: "gantt",
  receivedContracts: "received-contracts",
};

export default {
  components: {
    ContractDropdown,
    ContractList,
    ContractRequestModal,
    GanttChart,
  },

  props: {
    initialContracts: {
      type: Array,
      required: false,
      default: () => [],
    },

    initialReceivedContracts: {
      type: Array,
      required: false,
      default: () => [],
    },

    initialLastPossibleDate: {
      type: String,
      default: "9999-12-24",
    },

    hasContractNotifications: {
      type: Boolean,
      required: false,
      default: false,
    },

    unreadContractNotificationsCount: {
      type: Number,
      required: false,
      default: 0,
    },

    categories: {
      type: Array,
      required: false,
      default: () => [],
    },

    customFields: {
      type: Array,
      required: false,
      default: () => [],
    },

    canCreateContract: {
      type: Boolean,
      required: false,
      default: false,
    },

    canSendRequest: {
      type: Boolean,
      required: false,
      default: false,
    },

    canCreateCategory: {
      type: Boolean,
      required: false,
      default: false,
    },

    canCreateCustomField: {
      type: Boolean,
      required: false,
      default: false,
    },

    canUpdateCompany: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      activeTab: "contracts",
    };
  },

  computed: {
    ...mapGetters({
      contracts: "contracts/getContracts",
      receivedContracts: "contracts/getReceivedContracts",
      usedStoragePercentage: "company/usedStoragePercentage",
    }),

    tabsData() {
      return {
        contracts: {
          value: TABS.contracts,
          count: this.contracts.length,
        },

        receivedContracts: {
          value: TABS.receivedContracts,
          count: this.receivedContracts.length,
        },

        gantt: {
          value: TABS.gantt,
          count: null,
        },
      };
    },

    hasAnyContracts() {
      if (this.contracts?.length > 0 || this.receivedContracts?.length > 0) {
        return true;
      }

      return false;
    },

    isContractAdmin() {
      return this.$store.getters["company/isContractAdmin"]();
    },

    descriptionWithLink() {
      return this.$i18n.t(
        "components.contracts.contract_tabs.received_contracts_description_4_html",
        {
          link: `<a href="${this.url("/tokens")}">${this.$i18n.t(
            "components.contracts.contract_tabs.here"
          )}</a>`,
        }
      );
    },

    fields() {
      let fields = [
        {
          key: "title",
          label: this.$t("activerecord.attributes.contract.title"),
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.contract_number,
        },
        {
          key: "created_at",
          label: this.$t("activerecord.attributes.contract.received_at"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "administrator",
          label: this.$t("activerecord.attributes.contract.administrator_id"),
          class: "col-shrink text-center",
        },
        {
          key: "responsible_user",

          label: this.$t(
            "activerecord.attributes.contract.responsible_user_id"
          ),

          class: "col-shrink text-center",
        },
        {
          key: "actions",
          label: "",
          class: "col-shrink",
        },
      ];

      return fields;
    },

    exportModaltitle() {
      return this.$t("buttons.titles.export_w_title", {
        title: this.$t("activerecord.models.contract.other").toLowerCase(),
      });
    },
  },

  created() {
    this.$store.dispatch("contracts/setContracts", this.initialContracts);
    this.$store.dispatch(
      "contracts/setReceivedContracts",
      this.initialReceivedContracts
    );
  },

  methods: {
    async removeReceivedContract(contract) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: `#${contract.contract_number} - ${contract.title}`,
        })
      );

      if (isConfirmed) {
        try {
          const response = await axios.delete(
            this.url(`/contracts/${contract.id}`)
          );

          let idx = this.receivedContracts.findIndex(
            (existingContract) => existingContract.id == response.data.id
          );

          if (idx > -1) {
            this.receivedContracts.splice(idx, 1);
          }
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
