<template>
  <div v-if="annualReport" class="card mb-4">
    <div class="card-header d-flex align-items-center justify-content-between">
      <h5 class="card-title">
        {{ annualReport.display_title }}
      </h5>

      <be-dropdown v-if="annualReport.policy.destroy" ellipsis>
        <be-dropdown-group v-if="annualReport.versions.length > 1">
          <be-dropdown-item
            v-for="{ id, version, display_version } in annualReport.versions"
            :key="id"
            :href="url(`/annual_reports/${id}`)"
            :disabled="version == annualReport.version"
          >
            {{
              $t("components.annual_reports.version", {
                version: display_version,
              })
            }}
          </be-dropdown-item>
        </be-dropdown-group>

        <be-dropdown-group>
          <be-dropdown-item
            v-if="documents.length === 0"
            variant="danger"
            data-method="delete"
            :href="annualReportUrl"
            :data-confirm="
              $t('nav.confirm_delete_w_title', {
                title: annualReport.display_title,
              })
            "
          >
            {{ $t("buttons.titles.remove") }}
          </be-dropdown-item>

          <be-dropdown-item
            v-else
            v-be-modal="`delete-annual-report-${annualReport.id}-confirm`"
            variant="danger"
          >
            {{ $t("buttons.titles.remove") }}
          </be-dropdown-item>
        </be-dropdown-group>
      </be-dropdown>

      <be-modal
        v-if="documents.length > 0"
        :id="`delete-annual-report-${annualReport.id}-confirm`"
        :title="
          $t('nav.confirm_delete_w_title', {
            title: annualReport.display_title,
          })
        "
        centered
      >
        <be-alert variant="warning">{{
          $t("components.annual_reports.confirm_delete")
        }}</be-alert>

        <be-table-simple>
          <thead>
            <tr>
              <th></th>

              <th>
                {{ $t("activerecord.attributes.document.filename") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="document in documents" :key="`document-${document.id}`">
              <td class="col-shrink text-muted text-center">
                <i :class="document.fontawesome_icon"></i>
              </td>

              <td>{{ document.filename }}</td>
            </tr>
          </tbody>
        </be-table-simple>

        <template #footer="{ ok, cancel }">
          <be-button variant="light" @click="cancel">
            {{ $t("buttons.titles.cancel") }}
          </be-button>

          <be-button
            variant="danger"
            :href="annualReportUrl"
            data-method="delete"
            @click="ok"
          >
            {{ $t("buttons.titles.remove") }}
          </be-button>
        </template>
      </be-modal>
    </div>

    <div class="card-body">
      <be-nav wizard class="mb-5">
        <be-nav-item
          :active="inUpload"
          :disabled="uploadDisabled"
          @click="changeTab('setup_and_upload')"
        >
          {{ $t("components.annual_reports.navigation.setup_and_upload") }}
        </be-nav-item>

        <be-nav-item
          :active="inAudit"
          :disabled="auditDisabled"
          @click="changeTab('audit')"
        >
          {{ $t("components.annual_reports.navigation.audit") }}
        </be-nav-item>

        <be-nav-item
          v-if="!annualReport.is_foundation"
          :active="inAnnualMeeting"
          :disabled="annualMeetingDisabled"
          @click="changeTab('annual_meeting')"
        >
          {{ $t("components.annual_reports.navigation.annual_meeting") }}
        </be-nav-item>

        <be-nav-item
          :active="inDelivery"
          :disabled="deliveryDisabled"
          @click="changeTab('delivery')"
        >
          {{ $t("components.annual_reports.navigation.archive") }}
        </be-nav-item>
      </be-nav>

      <div class="tab-content">
        <upload-tab v-if="inUpload" />

        <audit-tab v-if="inAudit" />

        <annual-meeting-tab v-if="inAnnualMeeting" />

        <email-delivery-tab v-if="inEmailDelivery" />

        <manual-delivery-tab v-if="inManualDelivery" />

        <digital-delivery-tab v-if="inDigitalDelivery" />
      </div>
    </div>
  </div>
</template>

<script>
import AuditTab from "./AuditTab.vue";
import AnnualMeetingTab from "./AnnualMeetingTab.vue";
import EmailDeliveryTab from "./EmailDeliveryTab.vue";
import ManualDeliveryTab from "./ManualDeliveryTab.vue";
import DigitalDeliveryTab from "./DigitalDeliveryTab.vue";
import UploadTab from "./UploadTab.vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("annual_reports");

const TABS_BY_PHASES = [
  {
    tab: "setup_and_upload",
    phases: ["annual_report"],
  },
  {
    tab: "audit",
    phases: ["audit_report"],
  },
  {
    tab: "annual_meeting",
    phases: ["annual_meeting"],
  },
  {
    tab: "delivery",

    phases: [
      "digital_delivery",
      "manual_delivery",
      "email_delivery",
      "archived",
    ],
  },
];

export default {
  components: {
    AuditTab,
    AnnualMeetingTab,
    DigitalDeliveryTab,
    ManualDeliveryTab,
    EmailDeliveryTab,
    UploadTab,
  },

  provide() {
    return {
      annualReportId: this.annualReportId,
    };
  },

  props: {
    annualReportId: {
      type: Number,
      required: true,
    },

    standalone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      currentTab: "setup_and_upload",
      poller: null,
    };
  },

  computed: {
    annualReport() {
      return this.$store.getters["annual_reports/getAnnualReport"](
        this.annualReportId
      );
    },

    annualReportUrl() {
      return this.url(`/annual_reports/${this.annualReport.id}`);
    },

    currentPhase() {
      return this.annualReport?.phase;
    },

    enabledTabs() {
      let index = TABS_BY_PHASES.findIndex(({ phases }) =>
        phases.includes(this.currentPhase)
      );
      if (index == -1) {
        index = 0;
      }
      return TABS_BY_PHASES.slice(0, index + 1).map(({ tab }) => tab);
    },

    annualMeetingDisabled() {
      return !this.enabledTabs.includes("annual_meeting");
    },

    deliveryDisabled() {
      return !this.enabledTabs.includes("delivery");
    },

    auditDisabled() {
      return !this.enabledTabs.includes("audit");
    },

    uploadDisabled() {
      return !this.enabledTabs.includes("setup_and_upload");
    },

    inAudit() {
      return this.currentTab == "audit";
    },

    inUpload() {
      return this.currentTab == "setup_and_upload";
    },

    inDelivery() {
      return this.currentTab == "delivery";
    },

    inAnnualMeeting() {
      return this.currentTab == "annual_meeting";
    },

    inEmailDelivery() {
      return this.inDelivery && this.annualReport.delivery_method == "email";
    },

    inManualDelivery() {
      return this.inDelivery && this.annualReport.delivery_method == "manual";
    },

    inDigitalDelivery() {
      return this.inDelivery && this.annualReport.delivery_method == "digital";
    },

    documents() {
      return this.annualReport?.document_keys
        .map((key) => this.annualReport[key + "_document"])
        .filter((doc) => doc);
    },
  },

  watch: {
    currentPhase(value) {
      this.setTabByPhase();
      if (value == "archived") {
        this.stopPolling();
      }
    },
  },

  created() {
    if (this.standalone) {
      this.setAnnualReports([this.annualReport]);
    }
  },

  mounted() {
    if (this.annualReport.phase !== "archived") {
      this.startPolling();
    }
    this.setTabByPhase();
  },

  methods: {
    ...mapActions(["setAnnualReports", "reloadAnnualReport"]),

    changeTab(tab) {
      this.currentTab = tab;
    },

    setTabByPhase() {
      this.currentTab = this.enabledTabs[this.enabledTabs.length - 1];
    },

    startPolling() {
      this.poller = setInterval(
        () => this.reloadAnnualReport(this.annualReportId),
        5000
      );
    },

    stopPolling() {
      clearInterval(this.poller);
    },
  },
};
</script>
