<template>
  <div class="row">
    <div v-if="showOptionRow" class="col-12 mb-2">
      <div class="card">
        <div
          class="card-header border-bottom-0 rounded d-flex flex-column flex-md-row justify-content-end gap-1"
        >
          <be-button
            v-if="isTaskRestorable(task)"
            variant="outline-secondary"
            @click="restoreTask(task)"
          >
            {{ $t("buttons.titles.restore") }}
          </be-button>

          <be-button
            v-if="canMarkTaskAsDone(task)"
            variant="primary"
            @click="markTaskAsDone(task)"
          >
            {{ $t("models.task.mark_as_done") }}
          </be-button>

          <be-button
            v-else-if="isTaskRevertable(task)"
            variant="outline-secondary"
            @click="markTaskAsNotDone(task)"
          >
            {{ $t("models.task.revert_done") }}
          </be-button>

          <be-dropdown
            v-if="isTaskEditable(task) || isTaskDestroyable(task)"
            ellipsis
          >
            <be-dropdown-group>
              <be-dropdown-item
                v-if="isTaskEditable(task)"
                v-be-modal="`edit-task-${task.id}`"
              >
                {{ $t("buttons.titles.edit") }}
              </be-dropdown-item>

              <be-dropdown-item
                v-if="isTaskArchivable(task)"
                @click="archiveTask(task)"
              >
                {{ $t("buttons.titles.archive") }}
              </be-dropdown-item>
            </be-dropdown-group>

            <be-dropdown-group>
              <be-dropdown-item
                v-if="isTaskDestroyable(task)"
                :href="url(`/tasks/${task.id}`)"
                variant="danger"
                data-method="delete"
                :data-confirm="
                  $t('nav.confirm_delete_w_title', {
                    title: task.description,
                  })
                "
              >
                {{ $t("buttons.titles.remove") }}
              </be-dropdown-item>
            </be-dropdown-group>
          </be-dropdown>

          <task-form
            v-if="isTaskEditable(task)"
            :task="task"
            :modal-id="`edit-task-${task.id}`"
            :projects="projects"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-5 mb-2 mb-lg-0">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">
            {{ $t("components.tasks.single_task.information") }}
          </h5>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table vertical-align-top">
              <tbody>
                <tr v-if="task.meeting_data">
                  <th class="col-shrink">
                    {{ $t("activerecord.models.meeting.one") }}
                  </th>

                  <td>
                    <meeting-information :owner="task" :inline="false" />

                    <div
                      v-if="task.meeting_data.item_number"
                      class="small mt-1"
                    >
                      {{ $t("components.tasks.from_meeting_item") }}:
                      {{ task.meeting_data.item_number }}
                    </div>
                  </td>
                </tr>

                <tr v-if="task.user_name">
                  <th class="col-shrink">
                    {{ $t("activerecord.attributes.task.assigned") }}
                  </th>

                  <td>{{ task.user_name }}</td>
                </tr>

                <tr>
                  <th class="col-shrink">
                    {{ $t("activerecord.attributes.task.due_at") }}
                  </th>

                  <td>
                    <template v-if="task.due_at">
                      {{ $d(new Date(task.due_at), "date") }}
                    </template>

                    <template v-else> - </template>
                  </td>
                </tr>

                <tr v-if="task.updated_at">
                  <th class="col-shrink">
                    {{ $t("activerecord.attributes.task.updated_at") }}
                  </th>

                  <td>{{ $d(new Date(task.updated_at), "date") }}</td>
                </tr>

                <tr v-if="task.done_at">
                  <th class="col-shrink">
                    {{ $t("activerecord.attributes.task.done_at") }}
                  </th>

                  <td>{{ $d(new Date(task.done_at), "date") }}</td>
                </tr>

                <tr v-if="task.archived_at">
                  <th class="col-shrink">
                    {{ $t("activerecord.attributes.task.archived_at") }}
                  </th>

                  <td>{{ $d(new Date(task.archived_at), "date") }}</td>
                </tr>

                <tr v-if="hasActiveFeature('projects') && task.project_id">
                  <th class="col-shrink">
                    {{ $t("activerecord.attributes.task.project_id") }}
                  </th>

                  <td>
                    <a :href="url(`/projects/${task.project_id}`)">
                      {{ task.project.display_title }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="withoutHeader" class="mt-3">
            <be-button
              v-if="isTaskRestorable(task)"
              variant="outline-secondary"
              @click="restoreTask(task)"
            >
              {{ $t("buttons.titles.restore") }}
            </be-button>

            <be-button
              v-if="canMarkTaskAsDone(task)"
              variant="primary"
              @click="markTaskAsDone(task)"
            >
              {{ $t("models.task.mark_as_done") }}
            </be-button>

            <be-button
              v-else-if="isTaskRevertable(task)"
              variant="outline-secondary"
              @click="markTaskAsNotDone(task)"
            >
              {{ $t("models.task.revert_done") }}
            </be-button>

            <be-button
              v-if="isTaskArchivable(task)"
              variant="outline-secondary"
              @click="archiveTask(task)"
            >
              {{ $t("buttons.titles.archive") }}
            </be-button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-7">
      <div class="card mb-2 mb-lg-0">
        <div class="card-header">
          <h5 class="card-title">
            {{ $t("activerecord.models.task.one") }}
          </h5>
        </div>

        <div class="card-body">
          <div
            v-if="task.description"
            v-dompurify-html="task.description"
            class="text-pre-line"
          />
        </div>

        <div
          v-if="showGoToButton"
          class="card-footer d-md-flex justify-content-end"
        >
          <be-button
            v-be-tooltip="goToTaskDisabledTooltip"
            :href="task.link"
            :disabled="goToTaskDisabled"
            variant="outline-secondary"
          >
            {{
              $t("components.tasks.go_to_w_owner", {
                owner: taskOwnerTitle(task),
              })
            }}
          </be-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TaskForm from "./TaskForm.vue";
import MeetingInformation from "@/components/shared/meetings/MeetingInformation.vue";

import TaskMixin from "@/mixins/tasks";

export default {
  components: { MeetingInformation, TaskForm },

  mixins: [TaskMixin],

  props: {
    initialTask: {
      type: Object,
      required: true,
    },

    projects: {
      type: Array,
      required: false,
      default: () => [],
    },

    withoutHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      tasks: "tasks/getTasks",
      hasActiveFeature: "company/hasActiveFeature",
    }),

    task() {
      const index = this.tasks.findIndex(
        (existing) => existing.id === this.initialTask.id
      );
      if (index > -1) {
        return this.tasks[index];
      } else {
        return this.initialTask;
      }
    },

    project() {
      return this.projects.find(
        (project) => project.id === this.task.project_id
      );
    },

    showOptionRow() {
      return (
        !this.withoutHeader &&
        (this.canMarkTaskAsDone(this.task) ||
          this.isTaskRevertable(this.task) ||
          this.isTaskRestorable(this.task) ||
          this.isTaskEditable(this.task) ||
          this.isTaskDestroyable(this.task))
      );
    },

    showGoToButton() {
      return this.task.owner_type != null && this.task.link;
    },

    requiredFeature() {
      return this.taskRequiredFeature(this.task);
    },

    goToTaskDisabled() {
      return (
        this.requiredFeature && !this.hasActiveFeature(this.requiredFeature)
      );
    },

    goToTaskDisabledTooltip() {
      const feature = this.taskRequiredFeature(this.task);
      return {
        title: this.featureRequiredTitle(feature),
        disabled: !this.goToTaskDisabled,
      };
    },
  },

  mounted() {
    this.$store.commit("tasks/updateTask", this.initialTask);
  },
};
</script>
