<template>
  <!-- Adds border-bottom-1 to work with .accordion.card which removes bottom border -->
  <div
    class="card bg-light border-bottom-1"
    :class="{
      'mt-2': index !== 0,
    }"
  >
    <div
      class="card-header bg-light d-flex justify-content-between align-items-center"
    >
      <div>
        <template v-if="isAgenda">
          {{ $t("components.meetings.material.item.decision_suggestion") }}
        </template>

        <template v-else>
          {{ $t("activerecord.models.decision.one") }}
        </template>

        <span v-if="decisionNumber" class="text-muted">
          ({{ localDecision.human_reference_prefix_part
          }}{{ meeting.number }}-{{ decisionNumber }})
        </span>
      </div>

      <div>
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          icon="fa-times"
          @click="handleRemove"
        />
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('description')"
            :label="translateAttribute('decision', 'description')"
            :error="getErrors(decision, 'description')"
            required
          >
            <be-form-textarea
              :id="inputId('description')"
              v-model="localDecision.description"
              :rows="9"
              :max-rows="20"
              :autofocus="localDecision.fresh"
              :state="validationState(localDecision, ['description'])"
              @input="updateAttribute('description', true)"
            />
          </be-form-group>

          <be-form-checkbox
            v-if="isAgenda"
            :id="`${inputId('include_in_agenda')}-${localDecision.id}`"
            v-model="localDecision.include_in_agenda"
            :state="validationState(localDecision, 'include_in_agenda')"
            @change="updateAttribute('include_in_agenda')"
          >
            {{ translateAttribute("decision", "include_in_agenda") }}
          </be-form-checkbox>
        </div>

        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('user_id')"
            :label="
              $t('components.meetings.material.decisions.assign_decision')
            "
            :error="getErrors(decision, 'user_id')"
          >
            <be-form-select
              :id="inputId('user_id')"
              v-model="localDecision.user_id"
              :state="validationState(localDecision, ['user_id'])"
              :options="userOptions"
              :include-blank-option="
                $t('components.meetings.material.decisions.unassigned')
              "
              @change="updateAttribute('user_id')"
            />
          </be-form-group>

          <be-form-group
            :label-for="inputId('due_at')"
            :label="translateAttribute('decision', 'due_at')"
            :error="getErrors(localDecision, 'due_at')"
          >
            <datepicker-future-buttons
              v-model="localDecision.due_at"
              :state="validationState(localDecision, ['due_at'])"
              display-inline
              @input="updateAttribute('due_at', true)"
            />
          </be-form-group>

          <be-form-group
            v-if="platformEnabledAndSubscribed('projects')"
            :label-for="inputId('project_id')"
            :label="translateAttribute('decision', 'project_id')"
            :error="getErrors(localDecision, 'project_id')"
          >
            <be-form-select
              :id="inputId('project_id')"
              v-model="localDecision.project_id"
              :state="validationState(localDecision, ['project_id'])"
              :options="projectOptions"
              :include-blank-option="$t('beta_shared.please_select_an_option')"
              @change="updateAttribute('project_id')"
            />
          </be-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
import debounce from "lodash/debounce";

import { EventBus } from "@/event-bus";

import materialType from "./materialState";
import TextUtilities from "@/mixins/textUtilities";

const materialHelpers = createNamespacedHelpers("material");
const AUTOSAVE_INTERVAL = 1000;

export default {
  mixins: [materialType, TextUtilities],

  props: {
    decision: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localDecision: this.cloneDeep(this.decision),
      updateDebounce: debounce(this.update, AUTOSAVE_INTERVAL),
    };
  },

  computed: {
    ...mapGetters({
      users: "company/users",
      projects: "company/projects",
      meeting: "material/meeting",
      decisionNumbers: "material/decisionNumbers",
    }),

    ...materialHelpers.mapGetters({
      meeting: "meeting",
      hasErrors: "hasErrors",
    }),

    projectOptions() {
      return this.projects.map((project) => ({
        text: project.display_title,
        value: project.id,
      }));
    },

    userOptions() {
      return this.users.map((user) => {
        return { value: user.id, text: user.name };
      });
    },

    invalid() {
      return this.hasErrors(this.localDecision);
    },

    descriptionErrors() {
      const value = this.getErrors(this.localDecision, "description");

      if (Array.isArray(value)) {
        return value;
      } else if (value) {
        return [value];
      } else {
        return [];
      }
    },

    decisionNumber() {
      return this.decisionNumbers[this.localDecision.id];
    },
  },

  watch: {
    decision: {
      handler(newDecision) {
        this.localDecision = this.cloneDeep(newDecision);
      },

      deep: true,
    },
  },

  methods: {
    ...materialHelpers.mapActions(["updateDecision", "removeDecision"]),
    ...materialHelpers.mapMutations(["setDirty"]),

    inputId(field) {
      return `decision-${this.decision.id}-${field}`;
    },

    async handleRemove() {
      let title = "";
      let itemType = this.$t("models.item.decision_suggestion");

      if (this.isMinutes) {
        itemType = this.$t("activerecord.models.decision.one");
      }

      if (this.decision.description) {
        title = this.$i18n.t("nav.confirm_delete_w_item_type_w_title", {
          item_type: itemType.toLowerCase(),
          title: this.truncateText(this.decision.description, 64),
        });
      } else {
        if (this.isAgenda) {
          title = this.$t(
            "models.material.minutes.confirm_remove_decision_suggestion"
          );
        } else {
          title = this.$i18n.t("nav.confirm_delete_w_item_type", {
            item_type: this.$t(
              "activerecord.models.decision.one"
            ).toLowerCase(),
          });
        }
      }

      const isConfirmed = await this.promptRemovalConfirm(title);

      if (isConfirmed) {
        this.removeDecision({
          decision: this.localDecision,
          item: this.item,
        });
      }
    },

    // Called after debounce
    async update() {
      const result = await this.updateDecision({
        decision: this.localDecision,
        item: this.item,
      });

      if (!result) {
        EventBus.emit("new-notification", {
          type: "danger",
          message: this.$t("models.decision.update.failure"),
        });
      }
    },

    // Called when a field changes
    updateAttribute(attribute, debounce = false) {
      this.clearErrors(this.localDecision, attribute);
      this.setDirty(`decision-${this.localDecision.id}`);
      if (debounce) {
        this.updateDebounce();
      } else {
        this.update();
      }
    },
  },
};
</script>
