<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{ $t("activerecord.models.task.other") }}
      </h5>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-lg-6 order-2 order-lg-1">
          <p>
            {{ $t("components.tasks.introduction.description") }}
          </p>

          <p class="mb-4">
            {{ $t("components.tasks.introduction.description2") }}
          </p>

          <be-button
            v-if="allowedToCreate"
            v-be-modal.new-task
            variant="primary"
          >
            {{ $t("components.tasks.introduction.add_task") }}
          </be-button>
        </div>

        <div class="col-12 col-lg-6 order-1 order-lg-2">
          <be-img :src="imageSrc(imgSrcPath)" class="mb-3 mb-lg-0" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    allowedToCreate: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    imgSrcPath() {
      if (this.$platform.theme.theme_name === "bank1") {
        return "onboarding/intro/bank1/tasks.png";
      } else if (this.$platform.theme.theme_name === "riksb") {
        return "onboarding/intro/riksb/tasks.png";
      }

      return `onboarding/intro/boardeaser/tasks.png`;
    },
  },
};
</script>
