<template>
  <div>
    <be-table
      :items="documents"
      :fields="fields"
      sort-by="created_at"
      sort-desc
      :tbody-tr-class="rowClasses"
    >
      <template #title="{ item }">
        <document-link
          :document-id="item.id"
          :filename="item.filename"
          :icon="item.fontawesome_icon"
          :title="item.title"
        />
      </template>

      <template #icons="{ item }">
        <i
          v-if="item.owner_destroyed"
          v-be-tooltip="$t('components.documents.folder_row.owner_destroyed')"
          class="fal fa-ban fa-fw"
        />

        <i
          v-if="item.signature_status === 'signed'"
          v-be-tooltip="$t('components.documents.histories.e_signed')"
          class="fal fa-file-contract fa-fw"
        />

        <i
          v-else-if="item.signature_status === 'pending'"
          v-be-tooltip="$t('components.documents.histories.e_sign_in_progress')"
          class="fal fa-file-signature fa-fw"
        />

        <i
          v-if="!item.destroyable"
          v-be-tooltip="$t('models.document.tooltips.not_destroyable')"
          class="fal fa-lock fa-fw"
        />
      </template>

      <template #uploaded_by="{ item }">
        <user-avatar
          v-if="getUser(item.uploaded_by_id)"
          :user="getUser(item.uploaded_by_id)"
        />
      </template>

      <template #created_at="{ item }">
        {{ $d(new Date(item.created_at), { format: "mini" }) }}
      </template>

      <template #file_size="{ item }">
        {{ item.file_size }}
      </template>

      <template #actions="{ item }">
        <be-dropdown size="sm" ellipsis>
          <be-dropdown-group>
            <be-dropdown-item @click="handleShare(item)">
              {{ $t("components.documents.document_row.share_file") }}
            </be-dropdown-item>

            <be-dropdown-item v-if="!isRead(item)" @click="markAsRead(item)">
              {{ $t("components.documents.document_row.mark_as_read") }}
            </be-dropdown-item>

            <be-dropdown-item
              v-if="item.signature_status === 'available'"
              @click="handleSendForESign(item)"
            >
              {{ $t("components.documents.document_row.send_for_signatures") }}
            </be-dropdown-item>
          </be-dropdown-group>

          <be-dropdown-group>
            <be-dropdown-item
              v-if="documentDestroyable(item)"
              variant="danger"
              @click="handleDocumentRemove(item)"
            >
              {{ $t("buttons.titles.remove") }}
            </be-dropdown-item>
          </be-dropdown-group>
        </be-dropdown>
      </template>
    </be-table>

    <signature-form
      :doc="signatureDocument"
      :available-users="users"
      @document-updated="handleDocumentUpdated"
      @reset="() => (signatureDocument = {})"
    />

    <inform-user-modal
      :document="shareDocument"
      :form-url="url('documents/send_reminder')"
      exclude-current-user
      show-automatically
      @reset="() => (shareDocument = {})"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import InformUserModal from "../shared/InformUserModal.vue";

export default {
  components: {
    InformUserModal,
  },

  props: {
    initialDocuments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      shareDocument: {},
      signatureDocument: {},
      selectedUserIds: [],
    };
  },

  computed: {
    ...mapGetters({
      users: "company/users",
      documents: "documents/getDocuments",
      readingsForReference: "readings/readingsForReference",
    }),

    fields() {
      return [
        {
          key: "title",
          label: this.translateAttribute("document", "title"),
          sortable: true,
        },
        {
          key: "icons",
          label: "",
          class: "col-shrink text-muted text-right",
        },
        {
          key: "uploaded_by",
          label: this.$t("activerecord.attributes.document.uploaded_by"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$t("activerecord.attributes.document.created_at"),
          class: "col-shrink",
          sortable: true,
        },

        {
          key: "file_size",
          label: this.$t("models.document.size"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          class: "col-shrink text-muted",
        },
      ];
    },
  },

  created() {
    this.selectedUserIds = this.users.map((user) => user.id);
    this.setDocuments(this.initialDocuments);
  },

  methods: {
    ...mapActions("documents", ["deleteDocument"]),
    ...mapMutations("documents", ["setDocuments", "setDocument"]),

    deselectUser(userId) {
      const index = this.selectedUserIds.findIndex(
        (selectedUserId) => selectedUserId == userId
      );

      if (index > -1) {
        this.selectedUserIds.splice(index, 1);
      }
    },

    documentDestroyable(doc) {
      return doc.policy.destroy && doc.destroyable;
    },

    handleDocumentUpdated(document) {
      this.setDocument(document);
    },

    handleSendForESign(document) {
      this.signatureDocument = document;
    },

    handleShare(doc) {
      this.shareDocument = doc;
    },

    async markAsRead(doc) {
      try {
        const response = await axios.post(
          this.url(`/documents/${doc.id}/readings`)
        );

        this.setDocument({
          ...doc,
          user_reading: response.data,
        });
      } catch (error) {
        this.handleError(error);
      }
    },

    async handleDocumentRemove(doc) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: doc.filename,
        })
      );

      if (isConfirmed) {
        try {
          await this.deleteDocument(doc);
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    isRead(doc) {
      return (
        doc?.user_reading?.read ||
        this.readingsForReference("Document", doc.id).some(
          (reading) => reading.read && reading.user_id === this.$currentUser.id
        )
      );
    },

    rowClasses(doc) {
      if (this.isRead(doc)) {
        return "status-readings-read";
      }
    },
  },
};
</script>
