<template>
  <tr>
    <td class="text-left">{{ customField.title }}</td>

    <td class="col-shrink">
      <i
        v-if="!customField.policy.destroy"
        v-be-tooltip="$t('components.custom_fields.not_removable')"
        class="fal fa-lock ml-1 text-muted"
      />
    </td>

    <td class="d-none d-md-table-cell col-shrink">
      {{ $t(`models.custom_field.field_types.${customField.field_type}`) }}
    </td>

    <td class="d-none d-md-table-cell col-shrink">
      {{
        $t(
          `models.custom_field.boolean_values.${customField.marked_as_default}_value`
        )
      }}
    </td>

    <td class="d-none d-md-table-cell col-shrink">
      {{ fieldValue }}
    </td>

    <td class="col-shrink">
      <be-dropdown size="sm" ellipsis>
        <be-dropdown-group>
          <be-dropdown-item
            v-be-modal="`update-custom-field-${customField.id}`"
          >
            {{ $t("buttons.titles.edit") }}
          </be-dropdown-item>
        </be-dropdown-group>

        <be-dropdown-group>
          <be-dropdown-item
            v-if="customField.policy.destroy"
            variant="danger"
            @click="removeCustomField"
          >
            {{ $t("buttons.titles.remove") }}
          </be-dropdown-item>
        </be-dropdown-group>
      </be-dropdown>

      <edit-custom-field-modal
        :initial-custom-field="customField"
        @custom-field-updated="updateCustomField"
      />
    </td>
  </tr>
</template>

<script>
import EditCustomFieldModal from "./EditCustomFieldModal.vue";

export default {
  components: { EditCustomFieldModal },

  props: {
    initialCustomField: {
      type: Object,
      required: true,
    },
  },

  emits: ["custom-field-removed"],

  data() {
    return {
      customField: this.cloneDeep(this.initialCustomField),
    };
  },

  computed: {
    fieldValue() {
      if (this.customField.field_type == "numeric") {
        return Number(this.customField.default_value).toLocaleString();
      }

      return this.customField.default_value;
    },
  },

  methods: {
    async updateCustomField(customField) {
      try {
        const response = await axios.patch(
          this.url(`/custom_fields/${customField.id}`),
          {
            custom_field: customField,
          }
        );

        this.customField = response.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async removeCustomField() {
      if (!this.customField.policy.destroy) {
        return false;
      }

      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: this.customField.title,
        })
      );

      if (isConfirmed) {
        try {
          const response = await axios.delete(
            this.url(`/custom_fields/${this.customField.id}`)
          );

          this.$emit("custom-field-removed", response.data);
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
