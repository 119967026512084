<template>
  <be-modal
    :id="modalId"
    :title="modalTitle"
    size="lg"
    @close="onClose"
    @hidden="onHidden"
  >
    <div class="row">
      <be-form-group
        class="col-12 col-md-6"
        :label="translateAttribute('meeting', 'title')"
        label-for="meeting-title"
        :error="getErrors(localMeeting, ['title'])"
        required
      >
        <be-form-input
          id="meeting-title"
          v-model="localMeeting.title"
          max="64"
          required
          @change="clearErrors(localMeeting, ['title'])"
        />
      </be-form-group>

      <be-form-group
        class="col-12 col-md-6"
        :label="translateAttribute('meeting', 'number')"
        label-for="meeting-number"
        :error="getErrors(localMeeting, ['number'])"
      >
        <template #label-after>
          <i
            v-be-tooltip="$t('components.meetings.editor.unique_number_notice')"
            class="fal fa-question-circle ml-1"
          />
        </template>

        <be-input-group>
          <be-form-select
            v-if="meetingSeriesEnabled"
            id="meeting-series-type"
            v-model="localMeeting.meeting_series_type"
            :options="meetingSeriesTypes"
            required
          />

          <be-form-input
            id="meeting-number"
            v-model="localMeeting.number"
            min="1"
            type="number"
            number
            required
          />
        </be-input-group>
      </be-form-group>

      <be-form-group
        class="col-12 col-md-6"
        :label="translateAttribute('meeting', 'date')"
        label-for="meeting-date"
        label-class="d-none d-lg-block"
        :error="getErrors(localMeeting, ['start_at'])"
        required
      >
        <be-form-datepicker
          id="meeting-date"
          v-model="localMeeting.start_at"
          :max-date="new Date()"
          :clearable="localMeeting.meeting_type === 'historic_draft'"
        />
      </be-form-group>

      <be-form-group
        class="col-12 col-md-6"
        :label="translateAttribute('meeting', 'location')"
        label-for="meeting-location"
        :error="getErrors(localMeeting, ['location'])"
      >
        <be-form-input
          id="meeting-location"
          v-model="localMeeting.location"
          max="64"
          :state="validationState(localMeeting, ['location'])"
          @change="clearErrors(localMeeting, ['location'])"
        />
      </be-form-group>

      <div class="d-flex flex-column col-12 gap-3">
        <decision-form-historic
          v-for="(decision, index) in item.decisions"
          :key="`decision-${decision.id}`"
          :decision="decision"
          :index="index"
          :meeting="meeting"
          @update="updateDecision"
          @remove="removeDecision"
          @dirty="dirtyDecision"
        />

        <div>
          <be-button variant="outline-primary" @click="addDecision">
            {{ $t("components.meetings.material.item.add_decision") }}
          </be-button>
        </div>
      </div>
    </div>

    <template #footer="{ close }">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div>
          <save-status
            :loading="loading.length > 0"
            :unsaved-changes="isDirty"
            :last-saved-at="lastSavedAt"
            :validation-failed="invalid"
            :validation-failed-text="
              $t('components.meetings.material.editor.form_invalid')
            "
          />
        </div>

        <div>
          <be-button
            v-if="meeting.meeting_type === 'historic_draft'"
            v-be-tooltip="{
              title: $t('components.meetings.historic.create_meeting_tooltip'),
              disabled: canCreateMeeting,
            }"
            variant="primary"
            :disabled="!canCreateMeeting"
            @click="$emit('create-meeting', meeting)"
          >
            {{ $t("components.meetings.historic.create_meeting") }}
          </be-button>

          <be-button variant="light" @click="close">
            {{ $t("buttons.titles.close") }}
          </be-button>
        </div>
      </div>
    </template>
  </be-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DecisionFormHistoric from "./material/DecisionFormHistoric.vue";
import SaveStatus from "@/components/shared/SaveStatus.vue";
import autosave from "@/mixins/autosave";

export default {
  components: {
    DecisionFormHistoric,
    SaveStatus,
  },

  mixins: [autosave],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    modalId: {
      type: String,
      required: true,
    },
  },

  emits: ["saved", "create-meeting"],

  data() {
    return {
      localMeeting: this.cloneDeep(this.meeting),
      dirty: [],
      loading: [],
    };
  },

  computed: {
    ...mapGetters("company", ["hasLoadedProjects", "hasActiveFeature"]),

    item() {
      return this.localMeeting.minutes.items[0];
    },

    minutes() {
      return this.localMeeting.minutes;
    },

    isDirty() {
      return this.dirty.length > 0;
    },

    canCreateMeeting() {
      return (
        this.meeting.title &&
        this.meeting.start_at &&
        this.meeting.number &&
        !this.isDirty
      );
    },

    invalid() {
      return (
        this.hasErrors(this.localMeeting) ||
        this.item.decisions.some((d) => this.hasErrors(d))
      );
    },

    lastSavedAt() {
      let date = new Date(this.localMeeting.updated_at);
      this.item.decisions.forEach((decision) => {
        let decisionDate = new Date(decision.updated_at);
        if (decisionDate > date) {
          date = decisionDate;
        }
      });

      return date;
    },

    disabledDates() {
      return {
        from: new Date(),
      };
    },

    meetingSeriesTypes() {
      return [
        {
          text: this.$t("models.meeting.meeting_series_type.board.one"),

          value: "board",
        },
        {
          text: this.$t("models.meeting.meeting_series_type.annual.one"),

          value: "annual",
        },
      ];
    },

    meetingSeriesEnabled() {
      return (
        this.$platform.features.meeting_series &&
        this.flipperFlag("meeting_series_flipper")
      );
    },

    modalTitle() {
      let title = this.$t("components.meetings.historic.edit");

      if (this.meeting.minutes.external_document) {
        title += ` (${this.meeting.minutes.external_document.filename})`;
      }

      return title;
    },
  },

  watch: {
    meeting: {
      handler(newMeeting) {
        // Set updateDisabled = true to allow to skip one watch of localDecision
        this.updateDisabled = true;
        this.localMeeting = this.cloneDeep(newMeeting);
        this.loading = this.loading.filter((l) => l !== "meeting");
        this.dirty = this.dirty.filter((d) => d !== "meeting");
      },

      deep: true,
    },

    localMeeting: {
      handler(updatedMeeting) {
        if (!this.updateDisabled) {
          this.dirty.push("meeting");
          this.tryAutoSave(updatedMeeting);
        }

        this.updateDisabled = false;
      },

      deep: true,
    },
  },

  created() {
    // Load Projects
    if (
      this.hasActiveFeature({ name: "projects" }) &&
      !this.hasLoadedProjects
    ) {
      this.loadProjects();
    }
  },

  methods: {
    ...mapActions("company", ["loadProjects"]),

    hasErrors(object) {
      return Object.keys(object.errors || {}).length > 0;
    },

    async saveMeeting(meeting) {
      try {
        this.loading.push("meeting");
        const { data } = await axios.patch(
          this.url(`/meetings/historics/${meeting.id}`),
          {
            meeting: meeting,
          }
        );

        this.updateDisabled = true;
        this.localMeeting = data;
        this.dirty = this.dirty.filter((d) => d !== "meeting");
        this.$emit("saved", data);
      } catch (e) {
        if (e.response?.status === 422) {
          this.updateDisabled = true;
          this.localMeeting = e.response.data;
        } else {
          this.handleError(e);
        }
      } finally {
        this.loading = this.loading.filter((l) => l !== "meeting");
      }
    },

    async addDecision() {
      try {
        const { data } = await axios.post(
          `${this.localMeeting.paths.base}/materials/${this.minutes.id}/items/${this.item.id}/decisions`
        );

        this.item.decisions.push(data);
      } catch (e) {
        this.handleError(e);
      }
    },

    async updateDecision(decision) {
      const reference = `decision-${decision.id}`;
      try {
        this.loading.push(reference);
        const { data } = await axios.patch(
          `${this.localMeeting.paths.base}/materials/${this.minutes.id}/items/${this.item.id}/decisions/${decision.id}`,
          { decision }
        );

        const index = this.item.decisions.findIndex(
          (d) => d.id === decision.id
        );

        if (index > -1) {
          this.item.decisions[index] = data;
        } else {
          this.item.decisions.push(data);
        }

        this.dirty = this.dirty.filter((id) => id !== decision.id);
      } catch (e) {
        if (e.response?.status === 422) {
          const index = this.item.decisions.findIndex(
            (d) => d.id === decision.id
          );

          if (index > -1) {
            this.item.decisions[index] = e.response.data;
          } else {
            this.item.decisions.push(e.response.data);
          }
        } else {
          this.handleError(e);
        }
      } finally {
        this.loading = this.loading.filter((l) => l !== reference);
      }
    },

    async removeDecision(decision) {
      try {
        await axios.delete(
          `${this.localMeeting.paths.base}/materials/${this.minutes.id}/items/${this.item.id}/decisions/${decision.id}`
        );

        const index = this.item.decisions.findIndex(
          (d) => d.id === decision.id
        );

        if (index > -1) {
          this.item.decisions.splice(index, 1);
        }
      } catch (e) {
        this.handleError(e);
      }
    },

    dirtyDecision(decision) {
      this.dirty.push(decision.id);
    },

    saveRequest(data) {
      this.saveMeeting(data);
      return true;
    },

    async onClose(event) {
      if (this.isDirty) {
        event.preventDefault();

        const isConfirmed = await this.promptConfirm({
          title: this.$t(
            "components.meetings.historic.unsaved_changes_confirm"
          ),
        });

        if (isConfirmed) {
          event.vueTarget.hide();
        }
      }
    },

    onHidden() {
      this.localMeeting = this.cloneDeep(this.meeting);
    },
  },
};
</script>
