<template>
  <be-dropdown variant="light" size="sm" @show="modifyTimepicker">
    <template #button-content>
      <span class="text-primary">{{ title }}</span>

      <be-badge variant="light" class="mx-1">
        {{ formattedDuration }}
      </be-badge>
    </template>

    <be-dropdown-form>
      <be-form-datepicker
        :model-value="time"
        type="time"
        :config="{ arrowLeft: '0px' }"
        time-inline
        auto-apply
        unstyled-wrapper
        is-24
        @input="valueUpdated"
      />
    </be-dropdown-form>
  </be-dropdown>
</template>

<script>
import { secondsToHMS, secondsToHM } from "@/utils/time";

export default {
  props: {
    modelValue: {
      type: Number,
      required: false,
      default: 0,
    },

    title: {
      type: String,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      time: this.convertSecondsToTime(this.modelValue),
      addedLabels: false,
    };
  },

  computed: {
    formattedDuration() {
      return secondsToHM(this.modelValue);
    },

    valueAsString() {
      return this.modelValue ? secondsToHMS(this.modelValue) : "00:00";
    },
  },

  watch: {
    value(newValue) {
      this.time = this.convertSecondsToTime(newValue);
    },
  },

  methods: {
    valueUpdated(value) {
      this.$emit("update:modelValue", this.convertTimeToSeconds(value));
    },

    convertSecondsToTime(seconds) {
      return {
        hours: Math.floor(seconds / 3600),
        minutes: Math.floor((seconds % 3600) / 60),
      };
    },

    convertTimeToSeconds(time) {
      return time.hours * 3600 + time.minutes * 60;
    },

    modifyTimepicker() {
      this.fixTimepickerHeight();
      this.addFormatLabels();
    },

    fixTimepickerHeight() {
      const timepicker = this.$el.querySelector(".dp__overlay");

      if (!timepicker) {
        return;
      }

      timepicker.style.height = "auto";
    },

    addFormatLabels() {
      if (this.addedLabels) {
        return;
      }

      const hourColumn = this.$el.querySelectorAll(".dp__time_col")[0];
      const middleColumn = this.$el.querySelectorAll(".dp__time_col")[1];
      const minuteColumn = this.$el.querySelectorAll(".dp__time_col")[2];

      if (!hourColumn || !minuteColumn) {
        return;
      }

      const hourLabel = document.createElement("div");
      hourLabel.classList.add("text-muted");
      hourLabel.classList.add("mt-2");
      hourLabel.style.fontSize = "0.75rem";
      hourLabel.textContent = "HH";

      const middleColumnSpacer = document.createElement("div");
      middleColumnSpacer.style.height = "1.7rem";
      middleColumn.appendChild(middleColumnSpacer);

      const minuteLabel = document.createElement("div");
      minuteLabel.classList.add("text-muted");
      minuteLabel.classList.add("mt-2");
      minuteLabel.style.fontSize = "0.75rem";
      minuteLabel.textContent = "MM";

      hourColumn.appendChild(hourLabel);
      minuteColumn.appendChild(minuteLabel);

      this.addedLabels = true;
    },
  },
};
</script>
